import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from './context/AuthContext';
import SignupModal from './components/SignupModal';
import LoginModal from './components/LoginModal';

const Navbar = () => {
  const { user, logout, authTokens } = useContext(AuthContext);
  const [showSignup, setShowSignup] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <Link to="/">Pyaar.ai</Link>
      </div>
      <ul className="navbar-links">
        {user ? (
          <>
            <li>Welcome, {user.username}</li>
            <li><button onClick={logout}>Logout</button></li>
          </>
        ) : (
          <>
            <li><Link onClick={() => setShowLogin(true)}>Login</Link></li>
            <li><Link onClick={() => setShowSignup(true)}>Signup</Link></li>
          </>
        )}
      </ul>
      <SignupModal show={showSignup} onClose={() => setShowSignup(false)} />
      <LoginModal show={showLogin} onClose={() => setShowLogin(false)} />
    </nav>
  );
};

export default Navbar;
