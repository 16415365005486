import React, { createContext, useState, useEffect } from 'react';

const AuthContext = createContext();
const DOMAIN = 'https://pyaar.ai';
// const DOMAIN = 'http://localhost:8008';

const AuthProvider = ({ children }) => {
  const [authTokens, setAuthTokens] = useState(() => 
    localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null
  );
  const [user, setUser] = useState(null);

  // State variables to manage loading, success, and error states
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (authTokens) {
      // Set the Authorization header for future fetch requests
      fetch.defaults = {
        headers: {
          'Authorization': `Token ${authTokens}`
        }
      };
      // Fetch user data to restore the session
      fetchUserData();
    }
  }, [authTokens]);

  const fetchUserData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${DOMAIN}/api/accounts/user/`, {
        method: 'GET',
        headers: {
          'Authorization': `Token ${authTokens}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setUser(data);  // Assuming the API returns user data
        setSuccess(true);
      } else {
        setError("Failed to fetch user data.");
        logout();  // Log out if the token is invalid
      }
    } catch (error) {
      setError("An error occurred while fetching user data.");
      logout();  // Log out if there's an error fetching the user data
    } finally {
      setLoading(false);
    }
  };

  const login = async (username, password) => {
    setLoading(true);
    setError(null);
    setSuccess(false);
    try {
      const response = await fetch(`${DOMAIN}/api/accounts/login/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });
  
      if (response.ok) {
        const data = await response.json();
        setAuthTokens(data.token);
        setUser({ username });
        localStorage.setItem('authTokens', JSON.stringify(data.token));
        setSuccess(true);
      } else {
        const errData = await response.json();
        setError(errData.detail || "Login failed.");
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const signup = async (username, email, password) => {
    setLoading(true);
    setError(null);
    setSuccess(false);
    try {
      const response = await fetch(`${DOMAIN}/api/accounts/signup/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        setAuthTokens(data.token);
        setUser({ username });
        localStorage.setItem('authTokens', JSON.stringify(data.token));
        setSuccess(true);
      } else {
        const errData = await response.json();
        setError(errData.username?.[0] || "Signup failed.");
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    setAuthTokens(null);
    setUser(null);
    localStorage.removeItem('authTokens');
    setSuccess(false);
  };

  return (
    <AuthContext.Provider value={{ authTokens, user, login, signup, logout, loading, error, success }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
