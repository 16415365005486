import React, {
  useState,
  useRef,
  useEffect,
  useCallback
} from 'react';
import { useLocation } from 'react-router-dom';
import musicNoteIcon from './music-note.svg';
import './App.css';
import * as Tone from 'tone';
import { Midi } from '@tonejs/midi';
import Navbar from './Navbar';

const DOMAIN = 'https://pyaar.ai';

function App() {
  const [audio_midi_id, setAudioMidiId] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState('Upload an MP3');
  const [isLoading, setIsLoading] = useState(false);
  const [hasEverLoaded, setHasEverLoaded] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState('');
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const fileInputRef = useRef(null);
  const [midi, setMidi] = useState(null);

  const location = useLocation();

  const playMidi = midi => {
    const synth = new Tone.PolySynth(Tone.Synth).toDestination();
    Tone.start();

    midi.tracks.forEach(track => {
      track.notes.forEach(note => {
        const startTime = Tone.now() + note.time;
        synth.triggerAttackRelease(note.name, note.duration, startTime, note.velocity);
      });
    });
  };

  const sendClickLocationEvent = click_location => {
    if (window.plausible) {
      window.plausible('click', {
        props: {
          click_location: click_location,
        }
      });
    }
  };

  const handleUpload = async (formData, endpoint) => {
    setIsLoading(true);
    setLoadingMessage('Processing upload...');
    if (!hasEverLoaded) setHasEverLoaded(true);

    try {
      const uploadResponse = await fetch(endpoint, { method: 'POST', body: formData });
      const uploadResult = await uploadResponse.json();
      if (!uploadResponse.ok) throw new Error(uploadResult.message || 'Failed to upload.');

      console.log('Upload result:', uploadResult);
      if (uploadResult.audio_midi_id) {
        setAudioMidiId(uploadResult.audio_midi_id);
        startMidiGeneration(uploadResult.audio_midi_id);
      } else {
        throw new Error('ID is missing from the upload response');
      }
    } catch (error) {
      console.error('Error in upload:', error);
      setError(error.message);
      setIsLoading(false);
    }
  };

  const resetState = () => {
    setAudioMidiId(null);
    setDownloadUrl(null);
    setLoadingMessage('Upload an MP3');
    setIsLoading(false);
    setHasEverLoaded(false);
    setProgress(0);
    setError('');
    setYoutubeUrl('');
    setMidi(null);
  };

  const startMidiGeneration = async id => {
    setLoadingMessage('Generating MIDI file...');
    const formData = new FormData();
    formData.append('audio_midi_id', id);
    formData.append('audio_chunk_length', 15);  // 15 seconds of transcribed audio

    try {
      const generateResponse = await fetch(`${DOMAIN}/transcribe/generate/`, {
        method: 'POST',
        body: formData,
      });
      const generateResult = await generateResponse.json();
      if (!generateResponse.ok) throw new Error(generateResult.message || 'Failed to generate MIDI.');

      checkTranscriptionStatus(id);
    } catch (error) {
      console.error('Error in MIDI generation:', error);
      setError(error.message);
      setIsLoading(false);
    }
  };

  const handleMp3Select = async event => {
    resetState();
    const file = event.target.files[0];
    if (!file || file.type !== 'audio/mpeg' || file.size > 20000000) {
      setError('Please ensure the file is an MP3 and is less than 20 MB.');
      setTimeout(() => setError(''), 4000);
      return;
    }

    sendClickLocationEvent('upload_audio_icon');

    const formData = new FormData();
    formData.append('audio', file);
    handleUpload(formData, `${DOMAIN}/transcribe/upload/`);
    event.target.value = null;
  };

  const handleYoutubeSubmission = () => {
    resetState();
    if (!youtubeUrl) {
      setError('Please enter a valid YouTube URL.');
      setTimeout(() => setError(''), 4000);
      return;
    }

    sendClickLocationEvent('upload_youtube_url');

    const formData = new FormData();
    formData.append('youtube_url', youtubeUrl);
    handleUpload(formData, `${DOMAIN}/transcribe/upload_from_yt/`);
  };

  const checkTranscriptionStatus = useCallback(async (audioId) => {
    try {
      const response = await fetch(`${DOMAIN}/transcribe/status/${audioId}`);
      const statusData = await response.json();
      if (statusData.status === 'completed') {
        const midiUrl = `${DOMAIN}/transcribe/download_midi_chunk/${audioId}/0`;
        setDownloadUrl(midiUrl);
        setLoadingMessage('Transcription complete!');
        setIsLoading(false);

        const midiResponse = await fetch(midiUrl);
        const midiArrayBuffer = await midiResponse.arrayBuffer();
        const midi = new Midi(midiArrayBuffer);
        setMidi(midi);

        setTimeout(() => {
          console.log('setting loading message');
          setLoadingMessage('Upload an MP3 to begin');
        }, 4000);
      } else {
        // TODO: Implement progress bar once backend supports it
        const currentProgress = (statusData.current_segment / statusData.num_transcription_segments) * 100;
        setProgress(currentProgress);
        setLoadingMessage(`${Math.round(currentProgress)}% transcribed`);
        setTimeout(() => checkTranscriptionStatus(audioId), 5000);
      }
    } catch (error) {
      console.error('Failed to fetch transcription status or MIDI file:', error);
      setError('Failed to fetch transcription status or MIDI file.');
      setIsLoading(false);
    }
  }, []);

  const downloadMidi = () => {
    sendClickLocationEvent('download_midi');
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'converted.midi');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const playMidiHandler = () => {
    sendClickLocationEvent('play_midi');
    playMidi(midi);
  };

  const shareMidiHandler = () => {
    if (midi && downloadUrl) {
      const shareUrl = `${window.location.origin}?sharedMidiId=${audio_midi_id}`;
      navigator.clipboard.writeText(shareUrl).then(() => {
        alert('Shareable URL copied to clipboard!');
      });
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sharedMidiId = params.get('sharedMidiId');
    if (sharedMidiId) {
      checkTranscriptionStatus(sharedMidiId);
    }
  }, [location.search, checkTranscriptionStatus]);

  return (
    <div className="App">
      <Navbar />
      <header className="App-header">
        {!error ? <p className="Header-text animated">{loadingMessage}</p> : <p className="error animated">{error}</p>}
        <img src={musicNoteIcon} className={`App-logo ${isLoading ? 'pulseRotate' : ''}`} alt="Music Note Icon" onClick={() => {
          sendClickLocationEvent('click_music_icon');
          fileInputRef.current.click();
        }} />
        {!hasEverLoaded && <span className="Header-text">or paste a Youtube URL</span>}
        <input type="file" accept=".mp3" onChange={handleMp3Select} style={{ display: 'none' }} ref={fileInputRef} />
        {!isLoading && (
          <div className="input-container">
            <input type="text" value={youtubeUrl} onChange={e => setYoutubeUrl(e.target.value)} placeholder="Paste YouTube URL here" className="youtube-input" />
            <button onClick={handleYoutubeSubmission} className="youtube-button animated">Upload</button>
          </div>
        )}
        {downloadUrl && (
          <div>
            <div className="button-container">
              <button className="button animated" onClick={downloadMidi}>Download MIDI</button>
              <button className="button animated" onClick={playMidiHandler}>Play MIDI</button>
              <button className="button animated" onClick={shareMidiHandler}>Share MIDI</button>
            </div>
          </div>
        )}
        {isLoading && <progress className="progress-bar" value={progress} max="100" />}
        <p className={`Description ${isLoading ? 'hidden' : 'typewriter'}`}>Transform songs into MIDI using AI</p>
      </header>

      {/* Add Content Sections */}
      <section id="home" className="section">
        <h2>Home</h2>
        <p>Welcome to Pyaar.ai - Transform songs into MIDI using AI.</p>
      </section>

      <section id="features" className="section">
        <h2>Features</h2>
        <ul>
          <li>High-accuracy transcription powered by Google <a href="https://research.google/pubs/mt3-multi-task-multitrack-music-transcription/">MT3</a></li>
          <li>Supports various audio formats</li>
          <li>Fast processing</li>
          <li>Downloadable MIDI files</li>
        </ul>
      </section>

      <section id="contact" className="section">
        <h2>Contact</h2>
        <p>If you have any questions, feel free to reach out to us at support@pyaar.ai.</p>
      </section>
    </div>
  );
}

export default App;
